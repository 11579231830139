

export const HeaderItems =({Icon, title}) =>  {
    return (<>
        <div className=" flex flex-col text-white items-center cursor-pointer group mr-10 mt-8 
        w-12 sm:w-20 hover:text-white p-2 "  >
            <Icon className="h-6 mb-1 group-hover:animate-bounce "/>
            
            <p className=" opacity-0 group-hover:opacity-100 text-xs  tracking-widest">{title}</p>
            
        </div>
        </>
    )
}


export const HeaderItemsactive =({Icon, title, }) =>  {
    return (
        <div className=" flex flex-col text-[#66615b] items-center cursor-pointer group mr-10 
        w-12 sm:w-20    focus:outline-none" >
            <Icon className="h-6 mb-1 group-hover:animate-bounce"/>
            
            <p className=" opacity-0 group-hover:opacity-100 text-xs tracking-widest">{title}</p>
            
        </div>
    )
}
export const MobheaderItemsactive =({Icon, title, }) =>  {
    return (
        <div className=" flex flex-col text-[#66615b] items-center cursor-pointer group mr-10 mt-10 
        w-12 sm:w-20 hover:text-[#66615b]" >
            <Icon className="h-8 mb-1 group-hover:animate-bounce"/>
            
            <p className=" opacity-0 group-hover:opacity-100  tracking-widest">{title}</p>
            
        </div>
    )
}
export const HeaderItemshome =({Icon, title}) =>  {
    return (
        <>
        <div className=" flex flex-col text-white items-center cursor-pointer group mr-10 mt-8 mb-10 rounded-xl
        w-12 sm:w-20 hover:text-white px-4 py-2 active:text-violet-600 focus:text-violet-600 bg-[#f92961]/[.90]" >
            <Icon className="h-6 mb-1 group-hover:animate-bounce "/>
            
            <p className="  group-hover:animate-bounce text-xs tracking-widest">{title}</p>
            
        </div>
        </>
    )
}
export const HeaderItemshomeactive =({Icon, title}) =>  {
    return (
        <div className=" flex flex-col text-[#66615b] items-center cursor-pointer group mr-10 
        w-12 sm:w-20 hover:text-[#66615b] active:text-violet-600 focus:outline-none" >
            <Icon className="h-6 mb-1 group-hover:animate-bounce "/>
            
            <p className=" opacity-0 group-hover:opacity-100 text-xs  tracking-widest">{title}</p>
            
        </div>
    )
}

export const Teamicon =({Icon, title}) =>  {
    return (
        <div className=" flex  text-white items-center cursor-pointer group  
          hover:text-white  active:text-violet-600 focus:text-violet-600" >
            <Icon className="h-8 mb-1 w-13 group-hover:animate-bounce "/>
            
            <p className=" opacity-0 group-hover:opacity-100  tracking-widest">{title}</p>
            
        </div>
    )
}
