import "./css/contactus.css"
import "./css/footer.css"
const Footer = () => {
  return (
    <>
    <div   className="home-section ">
    <div class="waves">
    <div class="wave" id="wave1"></div>
    <div class="wave" id="wave2"></div>
    <div class="wave" id="wave3"></div>
    <div class="wave" id="wave4"></div>
    </div>
      <video id="background-video" loop autoPlay muted>
        <source src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/final_61eaa93e679d8b00459ee034_787431%20(1).mp4?alt=media&token=c53d9f54-fb85-4f36-8ae8-33b50a4b1411" type="video/mp4" />
      </video>
     
      <div className="md:container text-center mx-auto py-20 home-container">
     
        <div>
          <p className="text-xl font-semibold text-gray">
            Join us to recieve latest notification
          </p>
          <p className="text-sm font-semibold text-gray">
            You can unsubscribe anytime
          </p>
        </div>
        <div className="input-areas">
          <form>
            <input
              className="py-2 px-5 rounded-sm mr-2 outline-0 border-0 text-lg mb-4 border-1 border-gray bg-black"
              name="email"
              type="email"
              placeholder="Your Email"
            />
            <button
              className="py-2 px-5 rounded-sm outline-none border-none font-semibold cursor-pointer text-white transition-all duration-700 ease-out"
              style={{ backgroundColor: "#ff0000" }}
              onClick={() => console.log("submit")}
              type="reset"
            >
              Subscribe
            </button>
          </form>
        </div>
        <p className="text-xs font-normal text-gray">
            All Right Reserved @OnewonLabs Pvt. Ltd.
          </p>
          
        {/* <div className="md:flex justify-center">
          <div className="flex">
            <div className="flex flex-col flex-start m-4 text-left w-48 box-border">
              <h2 className="mb-8 text-gray">About Us</h2>
              <p className="text-gray mb-2">How it works</p>
              <p className="text-gray mb-2">Testimonials</p>
              <p className="text-gray mb-2">Careers</p>
              <p className="text-gray mb-2">Investors</p>
              <p className="text-gray mb-2">Terms of Service</p>
            </div>
            <div className="flex flex-col flex-start m-4 text-left w-48 box-border">
              <h2 className="mb-8 text-gray">Contact Us</h2>
              <p className="text-gray mb-2">Contact</p>
              <p className="text-gray mb-2">Support</p>
              <p className="text-gray mb-2">Destinations</p>
              <p className="text-gray mb-2">Sponsorships</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col flex-start m-4 text-left w-48 box-border">
              <h2 className="mb-8 text-gray">Videos</h2>
              <p className="text-gray mb-2">Submit Video</p>
              <p className="text-gray mb-2">Ambassadors</p>
              <p className="text-gray mb-2">Agency</p>
              <p className="text-gray mb-2">Influencer</p>
            </div>
            <div className="flex flex-col flex-start m-4 text-left w-48 box-border">
              <h2 className="mb-8 text-gray">Social Media</h2>
              <p className="text-gray mb-2 cursor-pointer">Instagram</p>
              <p className="text-gray mb-2 cursor-pointer">Facebook</p>
              <p className="text-gray mb-2 cursor-pointer">Youtube</p>
              <p className="text-gray mb-2 cursor-pointer">Twitter</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
    </>
  );
};

export default Footer;

