import React from "react";
import "./css/bgsmoke.css";
const Aboutus = () => {
  return (
    <div className="bg-gradient-to-r from-black/[.70] to-[#f92961]/[.70]    z-o">
      <div className=" aboutbg opacity-30 " ></div>
      {/* <div style={{  
  backgroundImage: "url(" + "https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/clipart-piano-wavy-11.png?alt=media&token=66fc6c98-93da-4aeb-9a00-e2634f7b0ef8" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  zIndex:999,

}}>  */}
<div
        className="flex flex-wrap   " >  
        <div className="w-full md:w-5/12 px-4 z-11 mr-auto ml-auto  ">
      
          <div className="text-Rose-100 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-4 mt-10 shadow-lg rounded-full bg-[#ef4444] ">
            <img
              className="absolute animate-spin"
              src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/icon-5.png?alt=media&token=1a689a1f-52fc-4624-bd6f-6eabf69804b2"
            />
          </div>
          <h3 className="text-3xl mb-2 mt-1 font-semibold z-999 leading-normal text-[#ef4444] opacity-990" style={{zIndex:999}}>
            Working with us is a pleasure
          </h3>
          {/* <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                        Don't let your uses guess by attaching tooltips and popoves to
                        any element. Just make sure you enable them first via
                        JavaScript.
                    </p> */}
          <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-[#fff] opacity-990">
            OneWonLabs is an IT services company based in Khajuraho that
            specializes in web solutions, specifically website design &
            development, custom web applications, e-Commerce solutions, VPS and
            cloud hosting, school portal solutions and many more.
          </p>
          <a href="#team" className="font-bold mt-8 text-[#0e314c]">
            OneWon Creative Team
          </a>
        </div>
        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg ">
            <img
              alt="..."
              src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/teampic.jpg?alt=media&token=bd1adc0a-89b8-4667-bdbd-c8f9c90c7c12"
              className="w-full align-middle rounded-t-lg mt-6"
            />
            <div className="relative p-8  bg-gradient-to-r rounded-b-md   from-black/[.80] to-[#fbd775]/[.80] opacity-0.5">
              <h4 className="text-xl font-bold text-[#0e314c]">
                Top Notch Services
              </h4>
              <p className="text-md font-light mt-2 text-gray-300">
                Seeing as you're intrested in the digital revolution, why not to
                take first stel and reach out to us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
