import React from "react";
import "./css/team.css";
import { Teamicon } from "./HeaderItems";
import "react-multi-carousel/lib/styles.css";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 664, min: 0 },
      items: 1,
    },
  };

const Team = () => {
  return (
    <div >
      <div id="team" className="  bg-gradient-to-r from-black/[.70] to-[#f92961]/[.70]   ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 130"  >
          <path
            fill="#fff"
            fill-opacity="1"
           
            d="M0,0L60,0C120,0,240,0,360,10.7C480,21,600,43,720,69.3C840,96,960,128,1080,122.7C1200,117,1320,75,1380,53.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          ></path>
        </svg>
        <div>
          
          <h2 className="mt-4 uppercase text-center font-bold text-white ">Our Awesome Team</h2>
        </div>
        <div className="pb-40  px-16 ">
          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            autoPlay={true}
          >
            <div className="flex  ">
              <div class="teamcontainer">
                <div class="card">
                  <div class="teamcontent">
                    <div class="imgBx">
                      <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/Ravi.jpeg?alt=media&token=49dad259-89ff-474f-9d28-c9e34f730d82" />
                    </div>
                    <div class="contentBx">
                      <h3>
                        Ravi mishra <br />
                        <span></span>
                      </h3>
                    </div>
                  </div>
                  <ul class="sci">
                    <li class="i1">
                      <a href="https://www.facebook.com/ravimishraindia" target="_blank">
                        <Teamicon Icon={AiFillFacebook} />
                      </a>
                    </li>
                    <li class="i2">
                    <a href="https://twitter.com/ravi4khajuraho?s=08" target="_blank" >
                        <Teamicon Icon={AiFillTwitterSquare} />
                      </a>
                    </li>
                    <li class="i3">
                    <a href="https://www.linkedin.com/in/ravi-mishra-93b560202/"  >
                        <Teamicon Icon={AiFillLinkedin}  target="_blank"/>
                      </a>
                    </li>
                  </ul>
                </div>{" "}
              </div>
            </div>
            <div className="flex  ">
              <div class="teamcontainer">
                <div class="card">
                  <div class="teamcontent">
                    <div class="imgBx">
                      <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/TeamShweta.jpeg?alt=media&token=84f741aa-a561-4803-aada-bcb86b688490" />
                    </div>
                    <div class="contentBx">
                      <h3>
                        Shweta <br /> <span></span>
                      </h3>
                    </div>
                  </div>
                  <ul class="sci">
                    <li class="i1">
                      <a href="https://www.facebook.com/shweta.b.mishra.16" target="_blank">
                        <Teamicon Icon={AiFillFacebook} />
                      </a>
                    </li>
                    <li class="i2">
                    <a href="https://twitter.com"  target="_blank"  target="_blank">
                        <Teamicon Icon={AiFillTwitterSquare} /></a>
                    </li>
                    <li class="i3">
                      <a href="https://www.linkedin.com/in" target="_blank"  >
                        <Teamicon Icon={AiFillLinkedin} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex ">
              <div class="teamcontainer">
                <div class="card">
                  <div class="teamcontent">
                    <div class="imgBx">
                      <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/teamVicky.jpeg?alt=media&token=78921403-2285-4a90-a8cd-410aa0e6a4e9" />
                    </div>
                    <div class="contentBx">
                      <h3>
                        Vikash Mishra <br /> <span></span>
                      </h3>
                    </div>
                  </div>
                  <ul class="sci">
                    <li class="i1">
                      <a href="https://www.facebook.com/Vicky.Om.Mishra" target="_blank">
                        <Teamicon Icon={AiFillFacebook} />
                      </a>
                    </li>
                    <li class="i2">
                    <a href="https://twitter.com/VIKASHM72537435?s=08"  target="_blank" >
                        <Teamicon Icon={AiFillTwitterSquare} />
                      </a>
                    </li>
                    <li class="i3">
                      <a href="https://www.linkedin.com/in/vikash-mishra-73355a101"  target="_blank">
                        <Teamicon Icon={AiFillLinkedin} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex ">
              <div class="teamcontainer">
                <div class="card">
                  <div class="teamcontent">
                    <div class="imgBx">
                      <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/kapil.png?alt=media&token=ac50b6c8-fec1-43d0-8a5c-81122c449123" />
                    </div>
                    <div class="contentBx">
                      <h3>
                        Kapil Shukla <br /> <span></span>
                      </h3>
                    </div>
                  </div>
                  <ul class="sci">
                    <li class="i1">
                      <a href="https://www.facebook.com/profile.php?id=100007876454947" target="_blank">
                        <Teamicon Icon={AiFillFacebook} />
                      </a>
                    </li>
                    <li class="i2">
                    <a href="https://twitter.com" target="_blank"  >
                        <Teamicon Icon={AiFillTwitterSquare} />
                      </a>
                    </li>
                    <li class="i3">
                      <a href=" https://www.linkedin.com/in/kapil-shukla-706b4216b"  target="_blank">
                        <Teamicon Icon={AiFillLinkedin} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Team;

//      <div class="teambody">
//  <div class="teamcontainer">
//      <div class="card">
//          <div class="teamcontent">
//              <div class="imgBx"><img src ="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/Ravi.jpeg?alt=media&token=49dad259-89ff-474f-9d28-c9e34f730d82"/></div>
//              <div class="contentBx">
//                  <h3>Ravi mishra <br/><span></span></h3>

//              </div>
//          </div>
//          <ul class="sci">
//              <li class="i1">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillFacebook}  /></a>
//              </li>
//              <li class="i2">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillTwitterSquare} /></a>
//              </li>
//              <li class="i3">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillLinkedin} /></a>
//              </li>
//          </ul>
//      </div>
//      <div class="card">
//          <div class="teamcontent">
//              <div class="imgBx"><img src ="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/TeamShweta.jpeg?alt=media&token=84f741aa-a561-4803-aada-bcb86b688490"/></div>
//              <div class="contentBx">
//                  <h3>Shweta <br/> <span></span></h3>

//              </div>
//          </div>
//          <ul class="sci">
//              <li class="i1">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillFacebook}  /></a>
//              </li>
//              <li class="i2">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillTwitterSquare} /></a>
//              </li>
//              <li class="i3">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillLinkedin} /></a>
//              </li>
//          </ul>
//      </div>
//      <div class="card">
//          <div class="teamcontent">
//              <div class="imgBx"><img src ="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/teamVicky.jpeg?alt=media&token=78921403-2285-4a90-a8cd-410aa0e6a4e9"/></div>
//              <div class="contentBx">
//                  <h3>Vikash Mishra <br/> <span></span></h3>

//              </div>
//          </div>
//          <ul class="sci">
//              <li class="i1">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillFacebook}  /></a>
//              </li>
//              <li class="i2">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillTwitterSquare} /></a>
//              </li>
//              <li class="i3">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillLinkedin} /></a>
//              </li>
//          </ul>
//      </div>
//      <div class="card">
//          <div class="teamcontent">
//              <div class="imgBx"><img src ="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/Capture-1.png?alt=media&token=368c24dd-6592-477a-89a4-4aa06564846f"/></div>
//              <div class="contentBx">
//                  <h3>Kapil Shukla <br/> <span></span></h3>

//              </div>
//          </div>
//          <ul class="sci">
//              <li class="i1">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillFacebook}  /></a>
//              </li>
//              <li class="i2">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillTwitterSquare} /></a>
//              </li>
//              <li class="i3">
//                  <a href=""  target="_blank"><Teamicon Icon={AiFillLinkedin} /></a>
//              </li>
//          </ul>
//      </div>

//  </div>

// // </div>
