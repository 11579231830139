import React from "react";


import HeroSection from "./HeroSection";
import Teamok from "./Teamok";

import Contactus from "./Contactus";
import LatestBlogs from "./LatestBlogs";
import Webdevelopment from "./development/Webdevelopment";
import Aboutus from "./Aboutus";

import Softdevelopment from "./Softdevelopment";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Projects from "./projects";


function Home() {
  return (
    <div>
     
     
        
        <HeroSection />
        <Softdevelopment />
        <Aboutus />
        {/* <LatestBlogs /> */}
        <Projects/>
        <Teamok />
       
    
 
    </div>
  );
}

export default Home;
