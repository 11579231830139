import React from "react";
import { useMedia } from "./help/usemedia";

import styles from "./projects.module.css";

const data = [
  {
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/guruji-web-400e3.appspot.com/o/tosiai.png?alt=media&token=43c3dd53-a9ef-45c7-bbdb-f98a12f37d0e",
    url: "https://www.theschoolofai.com/",
  },
  {
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/guruji-web-400e3.appspot.com/o/consulux.png?alt=media&token=f2ccb250-8e0d-4a5a-9a17-b3d235574928",
    url: "https://conssulux.in/",
  },
 
  {
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/logoBekarmic.jpeg?alt=media&token=54e4daf8-74fc-442e-9a59-6661fe79e819",
    url: "https://www.bekarmic.com/",
  },
  {
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/guruji-web-400e3.appspot.com/o/yok.png?alt=media&token=f5b12060-4d9b-4d1a-a53e-286e63893952",
    url: "https://adhyatmyoga.org/",
  },
  {
    imgUrl:"https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/WhatsApp%20Image%202022-01-09%20at%2000.18.11.jpeg?alt=media&token=c2bf0ab8-52c8-4dda-93c8-e74435b29f7b",
    url: "https://play.google.com/store/apps/details?id=com.oneCinema",
  },
  {
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/logoEsi.jpeg?alt=media&token=c5cf8b06-1e03-44ad-b357-8f21c5802bf5",
    url: "https://eurostarinn.com/",
  },
  {
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/logo-GHI.jpg?alt=media&token=aa83a50a-e423-4f4b-aec0-be97cd5cd11d",
    url: "https://www.globalhealthinitiative.info/",
  },
  
];

const Projects = () => {
  const isMobile = useMedia(600);
  return (
    <div className={styles.rootComponent}>
      <div className={styles.innerComponent}>
      
        <h1>OUR PROJETS</h1>
        <div className={styles.underline} />
        <div>
        {isMobile ? <div className={styles.mobileMapComponent}>
        {data?.map((item, index) => {
          return (
            <div key={index} className={styles.mobileCardComponent}>
              <a href={item.url} target="_blank" rel="noreferrer">
                <img src={item.imgUrl} alt="projects" />
              </a>
            </div>
          );
        })}
      </div>: <div className={styles.mapComponent}>
        {data?.map((item, index) => {
          return (
            <div key={index} className={styles.cardComponent}>
              <a href={item.url} target="_blank" rel="noreferrer">
                <img src={item.imgUrl} alt="projects" />
              </a>
            </div>
          );
        })}
      </div>}
          
        </div>
      </div>
    </div>
  );
};

export default Projects;
