import React from "react";
import "./css/contactus.css"


const Contactus = () => {
  return (
    <><div id="contactus " className=" max-h-full bg-gradient-to-r from-black/[.80]  to-[#f92961]/[.80] pb-72 ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 340" className="opacity-1" >
          <path
            fill="black"
            fill-opacity="0.5"
             d="M0,320L60,277.3C120,235,240,149,360,117.3C480,85,600,107,720,138.7C840,171,960,213,1080,245.3C1200,277,1320,299,1380,309.3L1440,320L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
          
        <div className="container flex items-center  px-20 md:flex-row flex-col pb-10" >
            <div className="lg:max-w-lg lg:w-full md:pl-32 md:w-1/2 md:mb-0 ">
        <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/owl-4769122-3972395.png?alt=media&token=d40d056d-55d3-4063-8954-95f9df8f9b7c"
        width="300px" className="object-cover  rounded " /></div>
        <div className="lg:flex-grow md:w-1/2 text-6xl lg:pl-24 text-gray-200  flex flex-col md:items-start mb-3">
        <h1 className="">Contact us</h1>
        </div>
        </div>
      <section class="text-gray-600 body-font relative  ">
  <div class="container   mx-auto flex sm:flex-nowrap flex-wrap">
    <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg  sm:mr-10  flex items-end justify-start relative">
      <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=khajuraho%20eurostarinn+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
      <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">                                                                    

        <div class="lg:w-1/2 px-6">
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
          <p class="mt-1">OnewonLabs Pvt Ltd Khajuaho PinCode-471606</p>
        </div>
        <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
          <a href="mailto:ravi@onewon.in" class="text-indigo-500 leading-relaxed">ravi@onewon.in</a>
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
         <a href="tl:+918717964200"> <p class="leading-relaxed">+918717964200</p></a>
        </div>
      </div>
    </div>
    <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 px-4 mt-8 md:mt-0 bg-gradient-to-r from-black/[.80]  to-[#f92961]/[.80]">
      <h2 class="text-gray-900 text-lg mb-1 font-bold title-font">Talk to our team. Connect with OneWonLabs today</h2>
      {/* <p class="leading-relaxed mb-5 text-gray-600">Post-ironic portland shabby chic echo park, banjo fashion axe</p> */}
      <div class="relative mb-4">
        <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
        <input type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
        <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
        <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button class="text-white bg-black/[.50] border-0 py-2 px-6 focus:outline-none hover:bg-black/[.80] rounded text-lg">Submit</button>
      {/* <p class="text-xs text-gray-500 mt-3">Chicharrones blog helvetica normcore iceland tousled brook viral artisan.</p> */}
    </div>
  </div>
</section></div>
        
    </>
  );
};

export default Contactus;
