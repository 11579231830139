import React from "react";
import Footer from "./components/Footer";
import Contactus from "./components/Contactus";
import Headers from "./components/Headers";
import Home from "./components/Home";
import Webdevelopment from "./components/development/Webdevelopment";
import Mobdevelopment from "./components/development/Mobdevelopment";
import Softewaredeve from "./components/development/Softewaredeve";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Viki from './components/viki.html'

function App() {
  return (
    <div>
     <Router>
      <Headers />
      <Switch>
      
      <Route path="/contactus">
      <Contactus/>
        </Route>
        <Route path="/webdevelopment">
      <Webdevelopment />
        </Route>
        <Route path="/mobdevelopment">
      <Mobdevelopment />
        </Route>
        <Route path="/softewaredeve">
      <Softewaredeve />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />

      {/* <Router>
        <Headers />
        <HeroSection />
        <Softdevelopment />
        <Aboutus />
        <LatestBlogs />
        <Teamok />
        <Webdevelopment/>
        <Contactus/>
        <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
