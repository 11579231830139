import React, { useState } from "react";
import "./css/Header.css";
import { Link } from "react-router-dom";
import { IoMenu, IoCloseSharp } from "react-icons/io5";
import Contactus from "./Contactus";

import {
  HeaderItems,
  HeaderItemsactive,
  MobheaderItemsactive,
  HeaderItemshome,
  HeaderItemshomeactive,
} from "./HeaderItems";
import {
  HomeIcon,
  PhoneIcon,
  BriefcaseIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [header, setHeader] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <nav className={header ? "headeractive" : "header"}>
      <div className="header-container overflow-hidden ">
        <div className="app-logo sm:ml-16 ">
          <a href="/">
            {" "}
            <img
              className=" "
              src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/logoowlc.png?alt=media&token=38f5be66-4f52-4cac-a818-ef11c34abfb1"
              alt="logo of OneWonLabs Pvt Ltd"
            />
          </a>
        </div>
        <div className="items-container">
          <a href="/">
            {header ? (
              <HeaderItemshomeactive title="Home" Icon={HomeIcon} />
            ) : (
              <HeaderItemshome title="Home" Icon={HomeIcon} />
            )}
          </a>
          <a href="#middel">
            {header ? (
              <HeaderItemsactive title="Work" Icon={BriefcaseIcon} />
            ) : (
              <HeaderItems title="Work" Icon={BriefcaseIcon} />
            )}
          </a>
          <a href="#team">
            {header ? (
              <HeaderItemsactive title="Team" Icon={UserGroupIcon} />
            ) : (
              <HeaderItems title="Team" Icon={UserGroupIcon} />
            )}
          </a>

          <a href="/contactus">
            {header ? (
              <HeaderItemsactive title="Contact" Icon={PhoneIcon} />
            ) : (
              <HeaderItems title="Contact" Icon={PhoneIcon} />
            )}
          </a>
        </div>

        <div
          className="menu-container"
          onClick={() => setMobileMenu((prevState) => !prevState)}
        >
          {mobileMenu ? (
            <IoCloseSharp
              className={header ? "three-dotsactive" : "three-dots"}
            />
          ) : (
            <IoMenu className={header ? "three-dotsactive" : "three-dots"} />
          )}
        </div>
      </div>
      <div>
        {mobileMenu && (
          <div className="overflow-hidden">
            <div
              className={
                header ? "mobile-item-containeractive" : "mobile-item-container"
              }
            >
              <a href="/">
                {header ? (
                  <MobheaderItemsactive title="Home" Icon={HomeIcon} />
                ) : (
                  <HeaderItems title="Home" Icon={HomeIcon} />
                )}
              </a>
              <a href="#middel">
                {header ? (
                  <MobheaderItemsactive title="Work" Icon={BriefcaseIcon} />
                ) : (
                  <HeaderItems title="Work" Icon={BriefcaseIcon} />
                )}
              </a>
              <a href="#team">
                {header ? (
                  <MobheaderItemsactive title="Team" Icon={UserGroupIcon} />
                ) : (
                  <HeaderItems title="Team" Icon={UserGroupIcon} />
                )}
              </a>

              <a href="/contactus">
                {header ? (
                  <MobheaderItemsactive title="Contact" Icon={PhoneIcon} />
                ) : (
                  <HeaderItems title="Contact" Icon={PhoneIcon} />
                )}
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
