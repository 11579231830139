import React from "react";
import "./development.css"
const Webdevelopment =() => 
 {
  return (
    <>
      
          <div id="Webdevelopment" className="bg-gradient-to-r from-black/[.50] to-[#f92961]/[.50]  pb-72">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 340" className="opacity-1" >
          <path
            fill="black"
            fill-opacity="0.5"
             d="M0,320L60,277.3C120,235,240,149,360,117.3C480,85,600,107,720,138.7C840,171,960,213,1080,245.3C1200,277,1320,299,1380,309.3L1440,320L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
          

          <div className=" ">
           
              <section className="text-gray-600 body-font px-3">
                <div className=" flex  pt-0 pb-56  md:flex-row flex-col ">
                  <img
                    className="absolute lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-2 md:mb-8 animate-spin mt-8 "
                    src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/cercle-shape.png?alt=media&token=0f1f78f2-09c4-4f42-b18f-97a69ff21d31"
                    width="60%"
                  />
                  <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-2 mt-8 md:mb-0  ml-2">
                    <img
                      className="object-cover animate-bounce object-center rounded mt-20"
                      alt="hero"
                      src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/0734f6ecc032c998bf03e548e4d1fd65-removebg-preview.png?alt=media&token=7f399f80-f7c9-4cbd-af97-ac2417467644"
                      width="920px"
                    />
                     <img
                      className="object-cover object-center rounded mt-36"
                      alt="hero"
                      src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/Free-3D-Illustration-removebg-preview.png?alt=media&token=123e2ae4-74a2-4a56-9f6c-76cc545db6d1"
                      width="100%"
                    />
                  </div>
                

                  <div className="lg:flex-grow md:w-1/2 md:mt-0 lg:pl-24 md:pl-16  mt-24 flex flex-col md:items-start md:text-left items-center text-center">
                    <h2 className="font-semibold text-3xl font-sans capitalize text-[#0e314c]">
                    Web Development
                    </h2>
                    <div
                      className="mt-0 mb-0    "
                      style={{
                        
                        maxWidth: "30%",
                        height: "10%",
                      }}
                    >
                      <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/giphy.gif?alt=media&token=f173c7da-ecc6-41ae-8544-1d1772cc724f" />
                    </div>
                    <p className=" text-gray-200 md:pr-36">
                    Web Development Services Company with a skilled team of web developers. We are capable of composing high-end web development services, web solutions, web designing service, web development, web applications, and web portal development. All are with exquisitely robust user experiences and tailored-made to meet your specific industry needs.
                    </p>
                    <section class="text-gray-600 body-font">
  <div class="container px-5 py-10 mx-auto md:pr-20  flex flex-wrap">
    <div class="flex relative pt-10 pb-20 sm:items-center  mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">1</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">Shooting Stars</h2>
          <p class="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-20 sm:items-center  mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">2</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">The Catalyzer</h2>
          <p class="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-20 sm:items-center  mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">3</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <circle cx="12" cy="5" r="3"></circle>
            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">The 400 Blows</h2>
          <p class="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
        </div>
      </div>
    </div>
    <div class="flex relative pb-10 sm:items-center  mx-auto">
      <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">4</div>
      <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div class="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-12 h-12" viewBox="0 0 24 24">
            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        </div>
        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">Neptune</h2>
          <p class="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
        </div>
      </div>
    </div>
  </div>
</section>
                    
                  </div>
                </div>
              </section>
              </div>
          </div>
        
    </>
  );
};
export default Webdevelopment

