import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./css/softdevelopment.css"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 664, min: 0 },
    items: 1,
  },
};

const Softdevelopment = () => {
  return (
    <>
      
          <div id="middel" className="bg-gradient-to-r from-black/[.50] to-[#f92961]/[.50]  ">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290">
              <path
                fill="#fff"
                fill-opacity="1"
                d="M0,192L60,165.3C120,139,240,85,360,80C480,75,600,117,720,154.7C840,192,960,224,1080,240C1200,256,1320,256,1380,256L1440,256L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
              ></path>
            </svg>
          </div>

          <div className=" ">
            <Carousel
              swipeable={true}
              draggable={true}
              responsive={responsive}
              infinite={true}
              autoPlaySpeed={8000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              autoPlay={true}
            >
              <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-5 mb-24  md:flex-row flex-col items-center">
                  <img
                    className="absolute animate-spin"
                    src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/cercle-shape.png?alt=media&token=0f1f78f2-09c4-4f42-b18f-97a69ff21d31"
                  />
                  <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-2 mt-8 md:mb-0 animate-bounce ml-2">
                    <img
                      className="object-cover object-center rounded mt-4"
                      alt="hero"
                      src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/1_Z1KJzwqS3NUh7eAA1kl5fg-removebg-preview.png?alt=media&token=3ca3a00f-b862-4b18-b6b3-3f547b6dbfe8"
                      width="100%"
                      
                    />
                  </div>
                  <div className="lg:flex-grow bg-co md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h2 className="font-semibold text-3xl font-sans capitalize text-[#0e314c]">
                      Design &amp; Development
                    </h2>
                    <div
                      className="mt-0 mb-0  sticky   "
                      style={{
                        backgroundColor: "#fff",
                        maxWidth: "30%",
                        height: "0%",
                      }}
                    >
                      <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/giphy.gif?alt=media&token=f173c7da-ecc6-41ae-8544-1d1772cc724f" />
                    </div>
                    <p>
                    Analyze, solve complex challenges and develop solutions that create a revolution
                    </p>
                    <div className="flex flex-wrap   -m-2 mt-4 ">
                   
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full ">
                        <div className=" flex flex-wrap z-1 relative font-medium bg-gray-50   py-4 pr-3 pl-12 chips  ">
                          <svg
                            className=" object-cover  object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            color="#FCBBC7"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <rect
                              x="3"
                              y="3"
                              color="#FCBBC7"
                              width="18"
                              height="18"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="3" y1="9" x2="21" y2="9"></line>
                            <line x1="9" y1="21" x2="9" y2="9"></line>
                          </svg>{" "}
                          <div className="flex-grow ">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans   ">
                              {" "}
                              Responsive design
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap z-1  relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 rounded-sm chips">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            color="#FCBBC7"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polyline points="16 18 22 12 16 6"></polyline>
                            <polyline points="8 6 2 12 8 18"></polyline>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              React web development
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className="  flex h-full  items-center  flex-wrap z-1  relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <rect
                              x="5"
                              y="2"
                              width="14"
                              color="#FCBBC7"
                              height="20"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="12" y1="18" x2="12.01" y2="18"></line>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              Android apps development
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50  py-4 pr-3 pl-12 rounded-sm chips ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            color="#FCBBC7"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polyline points="16 18 22 12 16 6"></polyline>
                            <polyline points="8 6 2 12 8 18"></polyline>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              Laravel web development
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50  py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <rect
                              x="5"
                              y="2"
                              width="14"
                              height="20"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="12" y1="18" x2="12.01" y2="18"></line>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              iOS apps development
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <line x1="19" y1="5" x2="5" y2="19"></line>
                            <circle cx="6.5" cy="6.5" r="2.5"></circle>
                            <circle cx="17.5" cy="17.5" r="2.5"></circle>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              UX/UI design
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips  rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="9" cy="21" r="1"></circle>
                            <circle cx="20" cy="21" r="1"></circle>
                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              E-commerce development
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
                              className=""
                            ></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              Print ready design{" "}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-5 mb-24  md:flex-row flex-col items-center">
                  <img
                    className="absolute animate-spin mr-5"
                    src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/cercle-shape.png?alt=media&token=0f1f78f2-09c4-4f42-b18f-97a69ff21d31"
                  />
                  <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  ml-2">
                    <img
                      className="object-cover object-center rounded mt-0 ml-1"
                      alt="hero"
                      src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/PngItem_3109938%20(1).png?alt=media&token=1cb0d8eb-ed37-42b9-bba9-9fd32b8b1a8a"
                      width="100%"
                    />
                  </div>
                  <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h2 className="font-semibold text-3xl font-sans capitalize text-[#0e314c]">
                      Cloud Hosting Services
                    </h2>
                    <div
                      className="mt-0 mb-0  sticky   "
                      style={{
                        backgroundColor: "#fff",
                        maxWidth: "30%",
                        height: "0%",
                      }}
                    >
                      <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/giphy.gif?alt=media&token=f173c7da-ecc6-41ae-8544-1d1772cc724f" />
                    </div>
                    <p>
                    Web hosting services from  Cloud. Host everything from blogs to dynamic websites in the cloud with Click to Deploy or customized solutions.
                    </p>
                    <div className="flex flex-wrap   -m-2 mt-4 ">
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full ">
                        <div className=" flex flex-wrap z-1 relative font-medium   bg-gray-50 py-4 pr-3 pl-12 shadow-md chips rounded-sm  ">
                          <svg
                            className=" object-cover  object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            color="#FCBBC7"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                            <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                            <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                          </svg>{" "}
                          <div className="flex-grow ">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans    ">
                              {" "}
                              Cloud databases
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap z-1  relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            color="#FCBBC7"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="2" y1="12" x2="22" y2="12"></line>
                            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              Website hosting
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className="  flex h-full  items-center  flex-wrap z-1  relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips  rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                            <polyline points="13 2 13 9 20 9"></polyline>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              File storage
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            color="#FCBBC7"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                            <polyline points="17 6 23 6 23 12"></polyline>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              Forex trading
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips  rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              File backups
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <rect
                              x="2"
                              y="3"
                              width="20"
                              height="14"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="8" y1="21" x2="16" y2="21"></line>
                            <line x1="12" y1="17" x2="12" y2="21"></line>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              Remote desktop
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips  rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                            <polyline points="22,6 12,13 2,6"></polyline>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              {" "}
                              Email servers
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="box-border p-2 my-2 lg:w-1/2 md:col-span-1 w-full">
                        <div className=" flex h-full  items-center  flex-wrap relative font-medium text-[#6084a4] bg-gray-50 py-4 pr-3 pl-12 chips rounded-sm ">
                          <svg
                            className=" object-cover object-center flex-shrink-0 rounded-full mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            color="#FCBBC7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
                          </svg>{" "}
                          <div className="flex-grow">
                            {" "}
                            <h3 className=" text-[#6084a4] font-sans ">
                              Hybrid cloud{" "}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Carousel>
          </div>
        
    </>
  );
};

export default Softdevelopment;
