// import Secondheader from "./Secondheader";
// import Smoke from "./Smoke";
import Headers from "./Headers";
import "./css/bgsmoke.css";
import "./css/softdevelopment.css"
import { Link } from "react-router-dom";
const HeroSection = () => {
  return (
    <>
      <div className="   flex content-center items-center justify-center">
        <div className=" top-0  mb-0 bottom-0  w-full contrast-125 ">
          <div class="anim-body">
            <div class="fog-anim"></div>
          </div>
          {/* <video
            src="/videos/video-6.mp4"
            autoPlay
            loop
            muted
            className=" bg-cover w-full h-full "
          /> */}

          {/* <video autoPlay loop muted className="w-full bg-cover" >
                    <source src={vg} type="video/mp4"/>
                    </video> */}
        </div>

        <div className="container absolute mt-36   content-center items-center justify-center  mx-auto ">
          <div className="items-center  flex flex-wrap">
            <div className="w-full   lg:w-8/12  px-4 ml-auto mr-auto text-center">
              <div className="">
                <h2 className="presentation-title tracking-wide font-extrabold drop-shadow-[0_8px_25px_#000] text-white text-8xl  pt-2 ">
                  
                  OneWon Labs{" "}
                </h2>
                <h2 className="presentation-title drop-shadow-[0_8px_25px_#000] tracking-wide font-extrabold text-gray-200 text-8xl ">
                  Pvt Ltd
                </h2>
              </div>
            
            </div>
           
            <div className="absolute   ml-100  ">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/fog-low.png?alt=media&token=01bef1c8-1164-4c21-8f8b-83f2b1209618"
                className="w-200% h-200%   "
              />
            </div></div>
            <div className="home-logo">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/3dlogos.png?alt=media&token=7b54698b-b870-4bc3-9acd-d1d8501b95c8"
            alt="logo"
            width= "20px"
            className=" "
          />
        </div>
            
            <div className="container mb-8  mx-auto  items-center justify-center">
              <blockquote class="text-2xl mt-5 mx-9 font-semibold italic text-center text-gray-900 opacity-3 ">
            
                <span class=" before:block before:absolute before:-inset-4 before:-skew-y-2  before:bg-gradient-to-r from-black/[.80]  to-[#f92961]/[.80] relative inline-block">
                  <span class="relative text-white">
                    
                    {" "}
                    <p className="text-base drop-shadow-[0_0_10px_#000] mt-3 lg:text-3xl md:text-lg px-2 mx-8 xl:text-3xl 2xl:text-4xl text-#0e314c opacity-1">
                      We turn your thoughts and ideas into awesome digital
                      products.
                    </p>
                  </span>
                </span>
              </blockquote>
            </div>
          </div>
        </div>
      
      <div className=" justify-center  bg-gradient-to-r from-black/[.50] to-[#f92961]/[.50]   relative flex flex-wrap ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,128L60,106.7C120,85,240,43,360,32C480,21,600,43,720,80C840,117,960,171,1080,197.3C1200,224,1320,224,1380,224L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          ></path>
        </svg>
        <section className=" flex-wrap justify-center xl:-mt-27 2xl:-mt-16 md:-mt-52 lg:-mt-52 ">
          <div className="container mt-24 mx-auto px-14" />

          <div className="flex flex-wrap  ">


            
            <div className="lg:pt-12 pt-6   md:w-1/3 p-4 text-center   ">
              <div className="relative   flex-col min-w-0 break-words border-solid border-4 border-[#2ac6f0] bg-white h-full w-full   herochips  group block  mx-auto rounded-lg  bg-whitering-1 ring-gray-900/5 shadow-lg space-y-3  hover:bg-gradient-to-r from-gray-400 to-pink-200   hover:ring-sky-200 ">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 px-4 py-2 text-center inline-flex items-center justify-center w-40 h-8  pt-12 ">
                    <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/cg1.gif?alt=media&token=62cf219e-e923-4ed0-9858-c8f9f37710e3" 
                    width="100%"/>
                    <img
                      className="absolute animate-spin"
                      src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/cercle-shape.png?alt=media&token=0f1f78f2-09c4-4f42-b18f-97a69ff21d31"
                      width="25%"
                    />
                  </div>
                  <Link to="/webdevelopment">

                  <h6 className="text-xl font-semibold">Website Development</h6></Link>
                  <p className="  group-hover:text-[#2ac6f0] text-gray-600">
                  Web Development Services Company with a skilled team of web developers. We are capable of........</p>
               
                <a href="/webdevelopment" class="text-indigo-900 inline-flex items-center px-2 py-1 my-2 rounded-md bg-gray-100 hover:text-white hover:bg-black/[.80] font-bold lg:mb-0">Learn More
                <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a> </div>
              </div>
            </div>


            <div className="lg:pt-12 pt-6  md:w-1/3 p-4 text-center  ">
              <div className="relative  flex-col min-w-0 break-words border-solid border-4 border-[#fd7802]  bg-white group block h-full w-full  mx-auto rounded-lg  bg-whitering-1 ring-gray-900/5 shadow-lg space-y-3 herochips hover:bg-gradient-to-r from-gray-400 to-pink-200 hover:ring-sky-300">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white  px-4  text-center inline-flex items-center justify-center w-40 h-8 pt-8 ">
                    <img src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/ios-development.gif?alt=media&token=d8143fa3-95c0-4251-95fe-5850b9888e9e"
                    width="90%" />
                    <img
                      className="absolute animate-spin"
                      src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/cercle-shape.png?alt=media&token=0f1f78f2-09c4-4f42-b18f-97a69ff21d31"
                      width="20%"
                    />
                  </div>
                  <Link to="/mobdevelopment">
                  <h6 className="text-xl font-semibold">
                    Mobile App Development 
                  </h6></Link>
                  <p className="  group-hover:text-[#fd7802] text-gray-600">
                  Being an app development company, we work closely with our clients to first identify business ...........</p>
                
                <a href="/mobdevelopment" class="text-indigo-900 inline-flex items-center px-2 py-1 my-2 rounded-md bg-gray-100 hover:text-white hover:bg-black/[.80] font-bold lg:mb-0">Learn More
                <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
              </div></div>
            </div>


            <div className=" lg:pt-12 pt-6  md:w-1/3 p-4 text-center ">
              <div className="relative  flex-col min-w-0 break-words border-solid border-4 border-[#f93b94]  herochips bg-white h-full w-full  group block  mx-auto rounded-lg  bg-whitering-1 ring-gray-900/5 shadow-lg space-y-3  hover:bg-gradient-to-r from-gray-400 to-pink-200 hover:ring-sky-500">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 mt-3 px-4 py-5 text-center inline-flex items-center justify-center w-40 h-8  pt-8 ">
                    <img width="85%" src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/test-gadgets.gif?alt=media&token=d60a2cea-4402-40b3-8554-3d71bceab913" />
                    <img
                      className="absolute animate-spin "
                      src="https://firebasestorage.googleapis.com/v0/b/onewonlabs.appspot.com/o/cercle-shape.png?alt=media&token=0f1f78f2-09c4-4f42-b18f-97a69ff21d31"
                      width="25%"
                    />
                  </div>
                  <Link to="/softewaredeve">
                  <h6 className="text-xl font-semibold">
                    Software Development
                  </h6></Link>
                  <p className=" group-hover:text-[#f93b94] text-gray-600">
                  Software developers are the creative minds behind computer programs. As software development.......</p>
                  <a href="/softewaredeve" class="text-indigo-900 inline-flex items-center px-2 py-1 my-2 rounded-md bg-gray-100 hover:text-white hover:bg-black/[.80] font-bold lg:mb-0">Learn More
                <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
                </div>
              
              </div>
            </div>{" "}
           
          </div>
          
        </section>
      </div>
    </>
  );
};

export default HeroSection;
